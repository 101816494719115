import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCertCodeInFocus, setCertList, setCertCodeSubscribedList } from "../../../redux/slices/practiceSlice";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Alert from "../../../components/customMui/Alert";
import Button from "../../../components/customMui/Button";
import Modal from "../../../components/customMui/modal/Modal";
// import { CircularProgress } from "@mui/material";
import StripeCardElementForm from "../../../components/stripeCardElementForm/StripeCardElementForm";
import Image from "../../../components/image/Image";
import LoadingButton from "../../../components/customMui/LoadingButton";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import getCertColors from "../../../util/functions/getCertColors";
import "./subscriptionManage.css";
import useIsCertSubscribeAllowed from "../../../hooks/useIsCertSubscribeAllowed";

const SubscriptionManage = ({ onCertSubscribeClick }) => {
    const { certList, certCodeSubscribedList, certCodeInFocus } = useSelector((state) => state.practice);
    const [ formattedCertList, setFormattedCertList ] = useState([]);
    const [ selectedManageCert, setSelectedManageCert ] = useState(null);
    const [ popoverType, setPopoverType ] = useState(null);
    const [ popoverAnchorEl, setPopoverAnchorEl ] = useState(null);
    const [ loadingCertCode, setLoadingCertCode ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ subscribedCertSkeletonLoader, setSubscribedCertSkeletonLoader ] = useState(true);
    const [ showChangePaymentCardForm, setShowChangePaymentCardForm ] = useState(false);
    const [ subscriptionList, setSubscriptionList ] = useState(null);
    const [ cardDetails, setCardDetails ] = useState(null);
    const dispatch = useDispatch();
    const [ isCertSubscribeAllowed ] = useIsCertSubscribeAllowed();
    const isAllowed = isCertSubscribeAllowed();

    useEffect(() => {
        refreshCerts();
        createFormattedCertList();
        handleSetCardDetails();
    }, []);

    useEffect(() => {
        createFormattedCertList();
    }, [certList, certCodeSubscribedList]);


    const handleLocalDate = (data) => {
        const dt = moment.utc(data);
        const date = dt.local().format("M/D/YYYY");
        return `${date}`
    }

    const createFormattedCertList = () => {
        const newFormattedCertList = certList.map(cert => {
            return {
                ...cert,
                color: getCertColors(cert.cert.code)
            }
        })
        setFormattedCertList(newFormattedCertList);
    }

    const refreshCerts = async (certListRes = null) => {
        if (!certListRes) {
            setErrorMessage(null);
            certListRes = await standardBizApiClient.certification_list();
            if (certListRes?.is_error) {
                setErrorMessage("Error while getting subscription data, please try again soon.");
                return false;
            }
        }

        const subscriptionListRes = await standardBizApiClient.certification_subscription_list();
        if (subscriptionListRes?.is_error) {
            setErrorMessage("Error while getting subscription data, please try again soon.");
            return false;
        }
        setSubscriptionList(subscriptionListRes.subscriptions)
        setSubscribedCertSkeletonLoader(false)

        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certListRes.certs;
        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));
        return true;
    }

    const handleSetCardDetails = async () => {
        const cardDetailsRes = await standardBizApiClient.account_get_payment_method();
        if (cardDetailsRes?.is_error) {
            setErrorMessage("Error while gathering payment card details. Please refresh the page and try again.");
            return;
        }
        const newCardDetails = cardDetailsRes;
        setCardDetails(newCardDetails);
    }

    const handleChangePaymentMethod = async (paymentMethodId) => {
        const setPaymentMethodRes = await standardBizApiClient.account_set_payment_method(paymentMethodId);
        if (setPaymentMethodRes?.is_error) {
            setErrorMessage(
                <div>
                    Error while processing card details. <br/>
                    Please try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            );
            return;
        }
        setCardDetails(null);
        setShowChangePaymentCardForm(false);
        handleSetCardDetails();
    }

    const handleUnsubscribeButtonClick = (e, cert) => {

        setSelectedManageCert(cert);
        setPopoverType("unsubscribe");
        setPopoverAnchorEl(e.currentTarget);
    }

    const handlePopoverClose = () => {
        setSelectedManageCert(null);
        setPopoverType(null);
        setPopoverAnchorEl(null);
    }

    const handleUnsubscribeConfirm = async () => {
        const { code, name } = selectedManageCert.cert;
        handlePopoverClose();
        setErrorMessage(null);
        setLoadingCertCode(code);

        const certUnsubscribeRes = await standardBizApiClient.certification_unsubscribe(code);
        if (certUnsubscribeRes?.is_error) {
            setErrorMessage(`Error while unsubscribing from ${name}, please try again soon.`);
            setLoadingCertCode(null);
            return;
        }

        // if certInFocus is not null AND is not equal to the cert being unsubscribed
        // then send set focus request for the certInFocus
        if (certCodeInFocus !== null && certCodeInFocus !== code) {
            const certSetFocusRes = await standardBizApiClient.certification_set_focus(certCodeInFocus);
            if (certSetFocusRes?.is_error) {
                setErrorMessage(`Successfully unsubscribed from ${name}, but there was an error while reloading data. Please try refreshing the page.`);
                setLoadingCertCode(null);
                return;
            }
        }

        // if certInFocus is equal to the cert being unsubscribed AND there are other subscribed certs
        // then send set focus request for the first cert alphabetically (that is not the cert being unsubscribed)
        if (certCodeInFocus === code && certCodeSubscribedList.length > 1) {
            const notInFocusCertCodeSubscribedList = certCodeSubscribedList.filter(sCert => sCert !== certCodeInFocus);
            const newCertCodeInFocus = notInFocusCertCodeSubscribedList[0];
            const certSetFocusRes = await standardBizApiClient.certification_set_focus(newCertCodeInFocus);
            if (certSetFocusRes?.is_error) {
                setErrorMessage(`Successfully unsubscribed from ${name}, but there was an error while reloading data. Please try refreshing the page.`);
                setLoadingCertCode(null);
                return;
            }
        }

        // get the new cert list
        const certListRes = await standardBizApiClient.certification_list();
        if (certListRes?.is_error) {
            setErrorMessage(`Successfully unsubscribed from ${name}, but there was an error while reloading data. Please try refreshing the page.`);
            setLoadingCertCode(null);
            return false;
        }
        await refreshCerts(certListRes);

        setLoadingCertCode(null);
    }

    return (
        <div className="subscription-manage">
            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}

            {/* If the user has a card on file, show the details and a button to change it */}
            {cardDetails === null ? (
                <div className="subscription-manage-change-payment-method-container">
                    <Skeleton animation="wave" sx={{ width: "200px" }} />                
                    <Skeleton animation="wave" sx={{ width: "100%" }} />                
                </div>
            ) : cardDetails?.detail ? (
                <div className="subscription-manage-change-payment-method-container">
                    {showChangePaymentCardForm === false ? (
                        <>
                            <p className="font-header-bold" style={{ marginBottom: "10px" }}>Subscription Payment Card</p>
                            <div className="subscription-manage-change-payment-method-inner-container">
                                <div className="subscription-manage-change-payment-method-card">
                                    {/* Card Brands: amex, diners, discover, jcb, mastercard, unionpay, visa, unknown */}
                                    <Image src={`/icon/card/${cardDetails.detail.brand}.png`} alt={cardDetails.detail.brand} />
                                    <div>
                                        <p>****&nbsp;{cardDetails.detail.last4}</p>
                                        <p className="subscription-manage-change-payment-method-card-exp">
                                            {/* Enforce 2 digit exp month, leading 0 if less than 10 */}
                                            {parseInt(cardDetails.detail.exp_month) < 10 ? `0${cardDetails.detail.exp_month}` : cardDetails.detail.exp_month}/
                                            {cardDetails.detail.exp_year}
                                        </p>
                                    </div>
                                </div>
                                {isAllowed ? (
                                <Button                
                                    variant="text"
                                    
                                    customProps={{
                                        onClick: () => setShowChangePaymentCardForm(true)
                                    }}
                                >
                                    Change Payment Card
                                </Button>
                                ) : (
                                    <Button                
                                    variant="text"
                                    customProps={{
                                        disabled: true
                                  }}
                                >
                                    Change Payment Card
                                </Button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="font-header-bold" style={{ marginBottom: "15px" }}>Change Subscription Payment Card</p>
                            <Alert severity="info" style={{ marginBottom: "15px" }}>
                                After your payment card changes, 
                                all your active subscriptions will be billed to the new card starting on the next billing cycle.
                            </Alert>
                            <StripeCardElementForm 
                                onCancel={() => setShowChangePaymentCardForm(false)}
                                onSubmit={(paymentMethodId) => handleChangePaymentMethod(paymentMethodId)}
                            />
                        </>
                    )}
                </div>
            ) : null}

            <div className="subscription-manage-cert-container">
                {formattedCertList.map((cert, index) => {
                    const { name, code } = cert.cert;
                     if (name === "CISSP - 2021") {
                         return null;
                     }
                     const { is_subscribed } = cert.assoc;
                     const { color, color_dark, color_extralight } = cert.color;
                    return (
                        <div key={index} className="subscription-manage-cert">
                            <div className={`subscription-manage-cert-name-${is_subscribed && 'subscribed'} font-header-bold subscription-manage-cert-name`} style={{ borderColor: color, color: color }}>
                            {name}
                            </div>
                            {/* {loadingCertCode === code ? (
                                <div className={`subscription-manage-cert-name-loader-${is_subscribed && 'unsubscribe'}subscription-manage-cert-name-loader`}>
                                    <CircularProgress />
                                </div>
                            ) : null} */}

                            <div className={`subscription-manage-cert-content`}>
                                {is_subscribed ? (
                                    <div className="subscription-manage-cert-content-subscribed">

                                        <div className="subscription-manage-cert-content-details">
                                            {subscribedCertSkeletonLoader ? (
                                                <Stack spacing={1}>
                                                    <Skeleton variant="text" sx={{ fontSize: '0.9rem' }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '0.9rem' }} />

                                                </Stack>) : (
                                                subscriptionList?.[code]?.sub_state === "active" ?
                                                    <div>
                                                        {subscriptionList?.[code] && <span><b className="subscription-price">${subscriptionList?.[code]?.adjusted_price ? subscriptionList[code].adjusted_price : subscriptionList[code].price}</b> <span>Every </span>{subscriptionList?.[code]?.renewal_months} months</span>}
                                                        {subscriptionList?.[code]?.promocode?.code && <span>promo: {subscriptionList?.[code]?.promocode?.code} </span>}
                                                        {subscriptionList?.[code]?.anniversary_datetime
                                                            && <span>Renewal Date: {handleLocalDate(subscriptionList?.[code]?.anniversary_datetime)}</span>}

                                                    </div> : <div className="subscription-manage-cert-content-details">
                                                        <b>Unsubscribed</b>
                                                        <p>Access Expires :  {handleLocalDate(subscriptionList?.[code]?.anniversary_datetime)}</p>
                                                    </div>

                                            )

                                            }
                                        </div>
                                        {subscribedCertSkeletonLoader ? (
                                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                        ) : (
                                            subscriptionList?.[code]?.sub_state === "active" &&
                                            <div className="subscription-manage-cert-subscribed-button" onClick={(e) => handleUnsubscribeButtonClick(e, cert)}>

                                                <LoadingButton
                                                    variant="outlined"

                                                    // buttonColor={color}
                                                    // buttonColorSelected={color_dark}
                                                    // buttonColorFilledBackground={color_extralight}
                                                    customProps={{ disabled: Boolean(loadingCertCode), loading: loadingCertCode === code }}
                                                >
                                                    Unsubscribe
                                                </LoadingButton>

                                            </div>)}

                                    </div>
                                ) : (isAllowed ? (
                                    <Button
                                        variant="contained"
                                        customProps={{
                                            disabled: Boolean(loadingCertCode),
                                            onClick: () => onCertSubscribeClick(code, name),
                                            sx: { width: "130px" }
                                        }}
                                    >
                                        Get {name}
                                    </Button>) :(
                                    <Button
                                         variant="contained"
                                         customProps={{
                                              disabled: true,
                                            sx: { width: "130px" }
                                        }}
                                    >
                                        Get {name}
                                    </Button>)
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Modal
                title={popoverType === "subscribe" ? <h6>Subscribe to {selectedManageCert?.cert?.name}</h6> : <h6>Unsubscribe from {selectedManageCert?.cert?.name}</h6>}
                onCloseIconClick={handlePopoverClose}
                disableChildrenTopSpacing={true}
                customProps={{
                    open: Boolean(popoverAnchorEl),
                    onClose: handlePopoverClose,
                }}
            >
                <div className="subscription-manage-modal">
                    {popoverType === "subscribe" ? (
                        <p>Subscribe to the {selectedManageCert?.cert?.name} certification?</p>
                    ) : popoverType === "unsubscribe" ? (
                        <div className="subscription-manage-modal-unsubscribe">
                            <p>After unsubscribing, your access to {selectedManageCert?.cert?.name} will expire when the next billing cycle starts.</p>
                            <p>Are you sure you would like to unsubscribe from the {selectedManageCert?.cert?.name} certification?</p>
                        </div>
                    ) : null}
                    <div className="subscription-manage-modal-button-container">
                        <Button variant="outlined" customProps={{ onClick: handlePopoverClose }}>Cancel</Button>

                        <Button variant="contained" customProps={{ onClick: handleUnsubscribeConfirm }}>Unsubscribe</Button>

                    </div>
                </div>

            </Modal >
        </div>
    )
}

export default SubscriptionManage;

