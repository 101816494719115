import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PracticeAnswer from "../practiceTest/practiceAnswer/PracticeAnswer";
import Alert from "../../../components/customMui/Alert";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiPagination from '@mui/material/Pagination';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import getCssVariable from "../../../util/functions/getCssVariable";
import getCertColors from "../../../util/functions/getCertColors";
import "./practiceHistoryQuestions.css";

const PracticeHistoryQuestions = ({ 
    examSummary, 
    autoScrollOptions={ alignToTop: true, options: { behavior: "smooth" } } 
}) => {
    const { certCodeInFocus } = useSelector((state) => state.practice);
    const [ testDetails, setTestDetails ] = useState(null);
    const [ expandedPeqId, setExpandedPeqId ] = useState(null);
    const [ activeScrollId, setActiveScrollId ] = useState(null);
    const [ certColors, setCertColors ] = useState({
        // defaults for pagination
        color: "rgba(0, 0, 0, 0.23)",
        color_extralight: "rgba(0, 0, 0, 0.08)"
    });
    const [ pageTestDetails, setPageTestDetails ] = useState(null);
    const [ pageNumCurrent, setPageNumCurrent ] = useState(1);
    const [ pageNumMax, setPageNumMax ] = useState(1);
    const paginationInterval = 25;

    useEffect(() => {
        handleShowTestDetails();
    }, [examSummary]);

    useEffect(() => {
        handlePaginateTestDetails();
    }, [testDetails, pageNumCurrent]);

    useEffect(() => {
        handleGetCertColors();
    }, [certCodeInFocus]);

    const handleGetCertColors = () => {
        if (!certCodeInFocus) return;
        setCertColors(getCertColors(certCodeInFocus));
    }

    const handleShowTestDetails = async () => {
        const numberOfQuestions = examSummary.practice_question_list.length;
        if (numberOfQuestions > paginationInterval) {
            const newPageNumMax = Math.ceil(numberOfQuestions / paginationInterval);
            setPageNumMax(newPageNumMax);
        }
        const newTestDetails = examSummary.practice_question_list.map((practiceQuestion) => {
            let shortQuestionText = practiceQuestion.question.text;
            if (shortQuestionText.length > 85) {
                shortQuestionText = practiceQuestion.question.text.slice(0, 85).trim() + "...";
            }
            return {
                practice: {
                    ...practiceQuestion.practice
                },
                question: {
                    ...practiceQuestion.question,
                    text_short: shortQuestionText
                }
            }
        });
        setTestDetails(newTestDetails);
    }

    const handlePaginateTestDetails = () => {
        if (!testDetails) return;
        const startQuestionIndex = (pageNumCurrent - 1) * paginationInterval;
        const startQuestionNum = startQuestionIndex + 1;

        // get the current page of results
        let newTestDetails = testDetails.slice(
            startQuestionIndex,
            startQuestionIndex + paginationInterval
        )

        // add question numbers
        let curQuetsionNum = startQuestionNum;
        newTestDetails = newTestDetails.map(testDetail => {
            testDetail.question.question_number = curQuetsionNum;
            curQuetsionNum += 1;
            return testDetail;
        })

        setPageTestDetails(newTestDetails);
    }

    // when an accordian is opened, scroll it into view
    useEffect(() => {
        if (!expandedPeqId || !activeScrollId) return;
        try {
            const scrollEl = document.getElementById(activeScrollId);
            scrollEl.scrollIntoView(autoScrollOptions.alignToTop, autoScrollOptions.options); 
        } catch (error) {}
    }, [activeScrollId])

    const handleAccordianExpandedChange = (newExpandedPeqId, scrollId) => {
        if (newExpandedPeqId === expandedPeqId) {
            setExpandedPeqId(null);
            setActiveScrollId(null);
            return;
        }
        setExpandedPeqId(newExpandedPeqId);        
        setActiveScrollId(scrollId);
    }

    const Accordion = styled((props) => (
        <MuiAccordion {...props} />
    ))(({ theme }) => ({
        margin: "10px 4px !important",
        boxShadow: `1px 1px 4px ${getCssVariable("--wnp-color-secondary-light")}`,
        borderRadius: "6px"
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary {...props} />
    ))(({ theme }) => ({
        borderBottom: `1px solid ${getCssVariable("--wnp-color-secondary-extralight")}`
    }));

    if (!pageTestDetails) {
        return null;
    }

    const Pagination = styled((props) => (
        <MuiPagination {...props} />
    ))(({ theme }) => ({
        "& .MuiPaginationItem-root": {
            fontFamily: `${getCssVariable("--wnp-font-header-regular")} !important`
        },
        "& .Mui-selected": {
            backgroundColor: `${certColors.color_extralight} !important`,
            borderColor: `${certColors.color} !important`
        }
    }));

    const paginationControls = (
        <>
            {pageNumMax > 1 ? (
                <div className="practice-history-question-pagination">
                    <Pagination 
                        // showFirstButton={pageNumMax > 2}
                        // showLastButton={pageNumMax > 2}
                        variant="outlined" 
                        // shape="rounded"
                        count={pageNumMax} 
                        page={pageNumCurrent} 
                        onChange={(e, value) => {
                            setPageNumCurrent(value);
                        }}
                    />
                </div>
            ) : null}
        </>
    );

    return (
        <div>
            {pageTestDetails.length === 0 ? (
                <p>There are no questions in this test.</p>
            ) : null}

            {paginationControls}

            {pageTestDetails.map((detail, dIndex) => {
                const isExpanded = expandedPeqId === detail.practice.practice_exam_question_id;
                const scrollId = `practice-history-question-scroll-${dIndex}`;
                return (
                    <div key={dIndex + "d"} id={scrollId}>
                        <Accordion
                            expanded={isExpanded}
                            onChange={() => handleAccordianExpandedChange(detail.practice.practice_exam_question_id, scrollId)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="practice-history-question-accordian-summary">
                                    {detail.practice.question_state === "answer_pass" ? (
                                        <div className="practice-history-question-correct-icon">
                                            <CheckCircleIcon />
                                        </div>
                                    ) : detail.practice.question_state === "answer_fail" ? (
                                        <div className="practice-history-question-incorrect-icon">
                                            <CancelIcon />
                                        </div>
                                    ) : null}
                                    <p className="practice-history-question-accordian-question-text">
                                        <span className="font-header-regular practice-history-question-accordian-question-number">
                                            {detail.question.question_number}.
                                        </span>
                                        &nbsp;
                                        {isExpanded ? detail.question.text : detail.question.text_short}
                                    </p>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {detail.question.option_list.map((option, oIndex) => {
                                        let answerState = "disabled";
                                        if (detail.practice.answer_selection === option.option) {
                                            answerState = "incorrect";
                                        }
                                        if (detail.question.correct_answer_op === option.option) {
                                            answerState = "correct";
                                        }
                                        return (
                                            <div key={oIndex + "o"}>
                                                <PracticeAnswer
                                                    answerData={{ key: option.option, name: option.name }}
                                                    answerState={answerState}
                                                    size="small"
                                                    showEmptyBubble={false}
                                                />
                                            </div>
                                        )
                                    })}
                                    <Alert
                                        // severity => pass "success", fail "error", unanswered "info"
                                        severity={detail.practice.question_state === "answer_pass" ? "success" : detail.practice.question_state === "answer_fail" ? "error" : "info"}
                                        style={{
                                            marginTop: "15px",
                                            textAlign: "left"
                                        }}
                                    >
                                         {detail.question.topic ? (
                                            <p className="font-color-regular">
                                                <span className="font-header-bold">Topic:</span>&nbsp;
                                                {detail.question.topic}
                                            </p>
                                        ) : null}
    
                                        {detail.question.explanation ? (
                                            <p className="font-color-regular">
                                                <br />
                                                <span className="font-header-bold">Explanation:</span>&nbsp;
                                                {detail.question.explanation}
                                            </p>
                                        ) : null}
                                        {detail.question.why_not ? (
                                            <p className="font-color-regular">
                                                <br />
                                                <span className="font-header-bold">Why not:</span>&nbsp;
                                                {detail.question.why_not}
                                            </p>
                                        ) : null}                                        
                                    </Alert>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            })}

            {paginationControls}

        </div>
    )
}

export default PracticeHistoryQuestions;